<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isMenuLinkAddEditSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-add-edit-menu-link-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            v-if="menuLinkLocal.id"
            size="sm"
          >
            {{ $t('modules.menus.actions.edit_link') }}
          </h5>
          <h5
            v-else
            class="mb-0"
          >
            {{ $t('modules.menus.actions.add_link') }}
          </h5>
          <div>
            <feather-icon
              v-show="menuLinkLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-menu-link', menuLinkLocal.id); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Title -->
            <i-input
              id="itemName"
              ref="itemName"
              v-model="menuLinkLocal.title"
              :errors="[]"
              type="text"
              title="inputs.name"
              :validation-rules="['required']"
              :translatable="true"
            />
            <!-- Entity Type -->
            <i-input
              id="itemEntityType"
              ref="itemEntityType"
              v-model="menuLinkLocal.entity_type"
              :errors="[]"
              type="select"
              :reduce="o => o.key"
              :options="[
                {
                  key: 'PRODUCT',
                  label: $t('inputs.link_type_options.PRODUCT')
                },
                {
                  key: 'CATEGORY',
                  label: $t('inputs.link_type_options.CATEGORY')
                },
                {
                  key: 'PAGE',
                  label: $t('inputs.link_type_options.PAGE')
                },
                {
                  key: 'CUSTOM',
                  label: $t('inputs.link_type_options.CUSTOM')
                },
              ]"
              title="inputs.link_type"
              :validation-rules="['required']"
              :translatable="false"
              @input="val => loadEntityOptions(val)"
            />

            <Transition name="fade-bottom">
              <!-- Custom URL -->
              <i-input
                v-if="menuLinkLocal.entity_type === 'CUSTOM'"
                id="itemUrl"
                ref="itemUrl"
                v-model="menuLinkLocal.url"
                :errors="[]"
                type="text"
                title="inputs.url"
                :validation-rules="['required']"
                :translatable="false"
                class="text-left"
                :class="{'ltr': !!menuLinkLocal.url}"
              />
            </Transition>
            <Transition name="fade-bottom">
              <!-- Entity ID -->
              <i-input
                v-if="menuLinkLocal.entity_type && menuLinkLocal.entity_type !== 'CUSTOM'"
                id="itemEntityId"
                ref="itemEntityId"
                v-model="menuLinkLocal.entity_id"
                :errors="[]"
                type="select"
                :options="entityOptions"
                :reduce="o => String(o.id)"
                title="inputs.entity_id"
                :validation-rules="['required']"
                :translatable="false"
                :disabled="isEntityLoading"
                :input-bindings="{
                  loading: isEntityLoading
                }"
              />
            </Transition>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ $t(menuLinkLocal.id ? 'Edit' : 'Add') }}
              </b-button>
              <b-button
                v-t="`Cancel`"
                variant="outline-secondary"
                @click="hide"
              />
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs } from 'vue'
import useMenuLinkHandler from './useMenuLinkHandler'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,

    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isMenuLinkAddEditSidebarActive',
    event: 'update:is-add-edit-menu-link-sidebar-active',
  },
  props: {
    isMenuLinkAddEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    menuLink: {
      type: Object,
      required: true,
    },
    clearMenuLinkData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  watch: {
    isMenuLinkAddEditSidebarActive(newVal) {
      if (newVal && this.menuLinkLocal.entity_type) {
        this.loadEntityOptions(this.menuLinkLocal.entity_type)
      }
    },
  },
  setup(props, { emit }) {
    const {
      menuLinkLocal,
      resetMenuLinkLocal,

      entityOptions,
      isEntityLoading,
      loadEntityOptions,

      // UI
      onSubmit,
    } = useMenuLinkHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetMenuLinkLocal, props.clearMenuLinkData)

    return {
      entityOptions,
      isEntityLoading,
      loadEntityOptions,
      menuLinkLocal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // Filter/Formatter
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}
</style>
