import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import menuModel from './menuModel'

const {
  fetchList,
  fetchAll,
  fetchOne,
  create,
  update,
  deleteOne,
} = useModelStoreModule(menuModel)

// const {
//   fetchList: fetchCountries,
// } = useModelStoreModule(countryModel)
//
// const fetchAddresses = (ctx, id) => fetchAllChildren(ctx, id, 'addresses')

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchList,
    fetchAll,
    fetchOne,
    create,
    update,
    deleteOne,

    // fetchCountries,
    // fetchAddresses,
  },
}
