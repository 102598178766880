import { onUnmounted, ref, watch } from 'vue'
import store from '@/store'
import productStoreModule from '@/views/models/products/productStoreModule'
import productCategoryStoreModule from '@/views/models/product_categories/productCategoryStoreModule'
import pageStoreModule from '@/views/models/pages/pageStoreModule'
// import store from '@/store'

export default function useTaskHandler(props, emit) {
  // ------------------------------------------------
  // Dynamic Entities
  // ------------------------------------------------
  const PRODUCT_STORE_MODULE_NAME = 'products'
  const PRODUCT_CATEGORY_STORE_MODULE_NAME = 'productCategories'
  const PAGE_STORE_MODULE_NAME = 'pages'

  // Register module
  if (!store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.registerModule(PRODUCT_STORE_MODULE_NAME, productStoreModule)
  if (!store.hasModule(PRODUCT_CATEGORY_STORE_MODULE_NAME)) store.registerModule(PRODUCT_CATEGORY_STORE_MODULE_NAME, productCategoryStoreModule)
  if (!store.hasModule(PAGE_STORE_MODULE_NAME)) store.registerModule(PAGE_STORE_MODULE_NAME, pageStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_STORE_MODULE_NAME)
    if (store.hasModule(PRODUCT_CATEGORY_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_CATEGORY_STORE_MODULE_NAME)
    if (store.hasModule(PAGE_STORE_MODULE_NAME)) store.unregisterModule(PAGE_STORE_MODULE_NAME)
  })

  // ------------------------------------------------
  // menuLinkLocal
  // ------------------------------------------------
  const menuLinkLocal = ref(JSON.parse(JSON.stringify(props.menuLink.value)))
  const resetMenuLinkLocal = () => {
    menuLinkLocal.value = JSON.parse(JSON.stringify(props.menuLink.value))
  }

  const entityOptions = ref([])
  const isEntityLoading = ref(false)
  const loadedEntityType = ref(null)

  const loadDynamicEntityOptions = entityType => {
    let storeModuleName
    let payload = {}
    switch (entityType) {
      case 'PRODUCT':
        storeModuleName = PRODUCT_STORE_MODULE_NAME
        payload = { is_active: 1 }
        break
      case 'CATEGORY':
        storeModuleName = PRODUCT_CATEGORY_STORE_MODULE_NAME
        payload = { product_category_id: 'null' }
        break
      case 'PAGE':
        storeModuleName = PAGE_STORE_MODULE_NAME
        break
      default:
        return
    }

    isEntityLoading.value = true
    store.dispatch(`${storeModuleName}/fetchList`, payload).then(response => {
      if (response.data.success) {
        entityOptions.value = response.data.data
        isEntityLoading.value = false
      }
    })
  }
  watch(props.menuLink, () => {
    resetMenuLinkLocal()
  })

  const loadEntityOptions = entityType => {
    if (entityType && (entityType !== 'CUSTOM') && (loadedEntityType.value === entityType)) {
      return
    }
    loadDynamicEntityOptions(entityType)
    loadedEntityType.value = entityType
  }

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  // watch(props.isEventHandlerSidebarActive, val => {
  //   // ? Don't reset event till transition is finished
  //   if (!val) {
  //     setTimeout(() => {
  //       clearForm.value()
  //     }, 350)
  //   }
  // })

  const onSubmit = () => {
    const menuLinkData = JSON.parse(JSON.stringify(menuLinkLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.menuLink.value.id) emit('update-menu-link', menuLinkData.value)
    else emit('add-menu-link', menuLinkData.value)

    // Close sidebar
    emit('update:is-add-edit-menu-link-sidebar-active', false)
  }

  return {
    menuLinkLocal,
    resetMenuLinkLocal,

    entityOptions,
    isEntityLoading,
    loadEntityOptions,

    // UI
    onSubmit,
  }
}
