import { ref, watch } from 'vue'
// import store from '@/store'

export default function useMenuHandler(props, emit) {
  // ------------------------------------------------
  // menuLocal
  // ------------------------------------------------
  const menuLocal = ref(JSON.parse(JSON.stringify(props.menu.value)))
  const resetTaskLocal = () => {
    menuLocal.value = JSON.parse(JSON.stringify(props.menu.value))
  }
  watch(props.menu, () => {
    resetTaskLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  // watch(props.isEventHandlerSidebarActive, val => {
  //   // ? Don't reset event till transition is finished
  //   if (!val) {
  //     setTimeout(() => {
  //       clearForm.value()
  //     }, 350)
  //   }
  // })

  const onSubmit = () => {
    const itemData = JSON.parse(JSON.stringify(menuLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.menu.value.id) emit('update-menu', itemData.value)
    else emit('add-menu', itemData.value)

    // Close sidebar
    emit('update:is-menu-add-edit-sidebar-active', false)
  }

  return {
    menuLocal,
    resetTaskLocal,

    // UI
    onSubmit,
  }
}
