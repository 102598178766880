<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div class="add-task">
            <b-button
              v-if="canAdd"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="handleAddMenuClick()"
            >
              {{ $t('modules.menus.actions.add_menu') }}
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="menu in items"
                :key="menu.slug"
                :to="{ name: 'menus-links', params: { menu: menu.id }}"
                :active="selectedItem && (String(selectedItem) === String(menu.id))"
              >
                <span class="align-text-bottom line-height-1">{{ menu.title }}</span>
                <span
                  v-if="selectedItem && (String(selectedItem) === String(menu.id)) && !menu.is_locked"
                  class="float-right"
                  @click="handleEditMenuClick(menu)"
                >
                  <feather-icon icon="EditIcon" />
                </span>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>

    <menu-add-edit-sidebar
      v-model="isMenuAddEditSidebarActive"
      :menu="menu"
      :clear-menu-data="clearMenuData"
      @add-menu="payload => $emit('add-menu', payload)"
      @update-menu="payload => $emit('update-menu', payload)"
      @remove-menu="payload => $emit('remove-menu', payload)"
    />
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import MenuAddEditSidebar from '@/views/models/menus/MenuAddEditSidebar.vue'
import { ref } from 'vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    MenuAddEditSidebar,
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  emits: ['close-left-sidebar', 'add-menu', 'update-menu', 'remove-menu'],
  model: {
    prop: 'isMenuAddEditSidebarActive',
    event: 'update:is-menu-add-edit-sidebar-active',
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedItem: {
      type: [Number, String],
      required: false,
      default: null,
    },
    canAdd: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, ctx) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const isMenuAddEditSidebarActive = ref(false)

    const blankMenu = {
      id: null,
      title: '',
      dueDate: new Date(),
      description: '',
      assignee: null,
      tags: [],
      isCompleted: false,
      isDeleted: false,
      isImportant: false,
    }

    const menu = ref(JSON.parse(JSON.stringify(blankMenu)))

    const clearMenuData = () => {
      menu.value = JSON.parse(JSON.stringify(blankMenu))
    }

    const handleAddMenuClick = () => {
      ctx.emit('close-left-sidebar')

      clearMenuData()
      isMenuAddEditSidebarActive.value = true
    }

    const handleEditMenuClick = menuData => {
      ctx.emit('close-left-sidebar')

      menu.value = menuData
      isMenuAddEditSidebarActive.value = true
    }

    return {
      menu,
      isMenuAddEditSidebarActive,
      perfectScrollbarSettings,
      isDynamicRouteActive,

      clearMenuData,

      // Event Handlers
      handleAddMenuClick,
      handleEditMenuClick,
    }
  },
}
</script>

<style>

</style>
