<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="todo-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex justify-content-between">

        <!-- Toggle -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <div />
        <div>
          <b-button
            v-if="isSortingActive"
            id="save-sorting-btn"
            variant="success"
            class="mr-1"
            :disabled="isSaveSortedItemsLoading"
            @click="saveSortedItems"
          >
            <material-icon
              icon="save"
              class="mr-50"
              size="16"
            />
            <span
              v-t="`Save Order`"
              class="align-middle text-nowrap"
            />
          </b-button>
          <b-button
            v-if="isSortingActive"
            id="cancel-sorting-btn"
            variant="secondary"
            class="mr-1"
            :disabled="isSaveSortedItemsLoading"
            @click="resetSortedItems"
          >
            <material-icon
              icon="close"
              class="mr-50"
              size="16"
            />
            <span
              v-t="`Cancel Changes`"
              class="align-middle text-nowrap"
            />
          </b-button>

          <b-button
            variant="primary"
            class="mr-1"
            @click="handleAddMenuLinkClick(null)"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span
              v-t="`modules.menus.actions.add_link`"
              class="align-middle text-nowrap"
            />
          </b-button>
        </div>
      </div>

      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <draggable
          v-model="menuLinks"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list"
          :group="`menu-link-${selectedMenu}`"
          @change="handleOnDraggableMenuLinksChange"
        >
          <MenuNestableLink
            v-for="link in menuLinks"
            :key="link.id"
            :link="link"
            :nesting="true"
            @click-menu-link-add="handleAddMenuLinkClick"
            @click-menu-link-edit="handleEditMenuLinkClick"
            @remove-menu-link="handleDeleteMenuLinkClick"
            @draggable-change="handleOnDraggableMenuLinksChange"
          />
        </draggable>
        <div
          class="no-results"
          :class="{'show': !menuLinks.length}"
        >
          <h5>{{ $t('modules.menus._messages.no_links_found') }}</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Sidebars -->
    <menu-link-add-edit-sidebar
      v-model="isMenuLinkAddEditSidebarActive"
      :menu-link="menuLink"
      :clear-menu-link-data="clearMenuLinkData"
      @remove-menu-link="removeMenuLink"
      @add-menu-link="addMenuLink"
      @update-menu-link="updateMenuLink"
    />

    <!-- Left Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <menus-left-sidebar
        :is-menu-add-edit-sidebar-active.sync="isMenuAddEditSidebarActive"
        :is-menu-link-add-edit-sidebar-active.sync="isMenuLinkAddEditSidebarActive"
        :class="{'show': mqShallShowLeftSidebar}"
        :items="menus"
        :selected-item="selectedMenu"
        :can-add="!!selectedMenu && (menus.length < 10)"

        @remove-menu="handleDeleteMenuClick"
        @add-menu="addMenu"
        @update-menu="updateMenu"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import Vue, {
  ref, watch, computed, onUnmounted,
} from 'vue'
import {
  BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import { formatDate, avatarText } from '@core/utils/filter'
import { toast, useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import MenuNestableLink from '@/views/models/menus/MenuNestableLink.vue'
import menuStoreModule from '@/views/models/menus/menuStoreModule'
import menuLinkStoreModule from '@/views/models/menus/menuLinkStoreModule'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import MenusLeftSidebar from './MenusLeftSidebar.vue'
import MenuLinkAddEditSidebar from './MenuLinkAddEditSidebar.vue'

export default {
  components: {
    BButton,
    MenuNestableLink,
    draggable,
    VuePerfectScrollbar,

    // App SFC
    MenusLeftSidebar,
    MenuLinkAddEditSidebar,
  },
  setup(props, ctx) {
    const MENU_STORE_MODULE_NAME = 'menus'
    const MENU_LINK_STORE_MODULE_NAME = 'menuItems'

    // Register module
    if (!store.hasModule(MENU_STORE_MODULE_NAME)) store.registerModule(MENU_STORE_MODULE_NAME, menuStoreModule)
    if (!store.hasModule(MENU_LINK_STORE_MODULE_NAME)) store.registerModule(MENU_LINK_STORE_MODULE_NAME, menuLinkStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MENU_STORE_MODULE_NAME)) store.unregisterModule(MENU_STORE_MODULE_NAME)
      if (store.hasModule(MENU_LINK_STORE_MODULE_NAME)) store.unregisterModule(MENU_LINK_STORE_MODULE_NAME)
    })

    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)
    const selectedMenu = computed(() => route.value.params.menu)
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchMenuLinks()
    })

    const menus = ref([])
    const menuLinks = ref([])
    const isSortingActive = ref(false)
    const isSaveSortedItemsLoading = ref(false)

    const blankMenuLink = {
      id: null,
      menu_id: null,
      menu_link_id: null,
      title: {
        // en: null,
        // ar: null,
      },
      url: null,
      entity_type: null,
      entity_id: null,
      links: [],
    }
    const menuLink = ref(JSON.parse(JSON.stringify(blankMenuLink)))
    const clearMenuLinkData = () => {
      menuLink.value = JSON.parse(JSON.stringify(blankMenuLink))
    }

    const addMenu = val => {
      store.dispatch(`${MENU_STORE_MODULE_NAME}/create`, val)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchMenus()
        })
    }
    const updateMenu = menuData => {
      store.dispatch(`${MENU_STORE_MODULE_NAME}/update`, menuData)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchMenus()
        })
    }
    const removeMenu = menuId => {
      store.dispatch(`${MENU_STORE_MODULE_NAME}/deleteOne`, menuId)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          if (selectedMenu.value === menuId) {
            router.push({ name: 'menus-links', params: { menu: menus.value[0].id } })
          }
          fetchMenus()
        })
    }

    const addMenuLink = val => {
      store.dispatch(`${MENU_LINK_STORE_MODULE_NAME}/create`, val)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchMenuLinks()
        })
    }
    const updateMenuLink = menuLinkData => {
      store.dispatch(`${MENU_LINK_STORE_MODULE_NAME}/update`, menuLinkData)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchMenuLinks()
        })
    }
    const removeMenuLink = menuLinkId => {
      store.dispatch(`${MENU_LINK_STORE_MODULE_NAME}/deleteOne`, menuLinkId)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchMenuLinks()
        })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const isMenuAddEditSidebarActive = ref(false)
    const isMenuLinkAddEditSidebarActive = ref(false)

    const fetchMenus = () => {
      store.dispatch(`${MENU_STORE_MODULE_NAME}/fetchAll`)
        .then(response => {
          menus.value = response.data.data

          if (!selectedMenu.value && menus.value.length) {
            router.push({ name: 'menus-links', params: { menu: menus.value[0].id } })
          }
        })
    }

    fetchMenus()

    const fetchMenuLinks = () => {
      if (!selectedMenu.value) return
      store.dispatch(`${MENU_LINK_STORE_MODULE_NAME}/fetchAll`, {
        menu: selectedMenu.value,
        menu_link_id: null,
      })
        .then(response => {
          menuLinks.value = response.data.data
          isSortingActive.value = false
        })
        .catch(error => {
          if (error?.response?.status === 404) {
            router.push({ name: 'error-404' })
            return
          }
          throw error
        })
    }

    fetchMenuLinks()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const handleAddMenuLinkClick = asChildOf => {
      mqShallShowLeftSidebar.value = false
      isMenuAddEditSidebarActive.value = false
      clearMenuLinkData()
      menuLink.value.menu_id = selectedMenu.value
      menuLink.value.menu_link_id = asChildOf || null
      isMenuLinkAddEditSidebarActive.value = true
    }

    const handleEditMenuLinkClick = menuLinkData => {
      mqShallShowLeftSidebar.value = false
      isMenuAddEditSidebarActive.value = false
      menuLink.value = menuLinkData
      isMenuLinkAddEditSidebarActive.value = true
    }

    const { t } = useI18nUtils()
    const handleDeleteMenuClick = menuId => {
      Vue.swal({
        title: t('Are you sure?'),
        text: t('You won\'t be able to revert this!'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: t('Cancel'),
        confirmButtonText: `${t('Yes, delete')} ${t('this item')}!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          removeMenu(menuId)
          toast('success', t('Deleted!'), t('Item(s) has been deleted.'), 'CheckIcon')
        }
      })
    }
    const handleDeleteMenuLinkClick = menuLinkId => {
      Vue.swal({
        title: t('Are you sure?'),
        text: t('You won\'t be able to revert this!'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: t('Cancel'),
        confirmButtonText: `${t('Yes, delete')} ${t('this item')}!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          removeMenuLink(menuLinkId)
          toast('success', t('Deleted!'), t('Item(s) has been deleted.'), 'CheckIcon')
        }
      })
    }

    /** Sorting */
    const saveSortedItems = () => {
      isSaveSortedItemsLoading.value = true
      const payload = { id: selectedMenu.value, links: menuLinks.value.map(l => ({ id: l.id, links: l.links.map(sl => ({ id: sl.id })) })) }
      store.dispatch(`${MENU_STORE_MODULE_NAME}/update`, payload)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          toast('success', t('message.operation_success'))
          fetchMenuLinks()
        })
        .catch(() => {
          toast('danger', t('message.operation_failed'), '', 'AlertTriangleIcon')
        })
        .finally(() => {
          isSaveSortedItemsLoading.value = false
        })
    }
    const resetSortedItems = () => {
      isSortingActive.value = false
      fetchMenuLinks()
    }
    const handleOnDraggableMenuLinksChange = () => {
      isSortingActive.value = true
    }

    return {
      menus,
      selectedMenu,
      menuLink,
      menuLinks,

      addMenu,
      updateMenu,
      removeMenu,
      addMenuLink,
      updateMenuLink,
      removeMenuLink,
      clearMenuLinkData,
      fetchMenuLinks,

      perfectScrollbarSettings,

      // UI
      isMenuAddEditSidebarActive,
      isMenuLinkAddEditSidebarActive,

      // Event Handler
      handleAddMenuLinkClick,
      handleEditMenuLinkClick,
      handleDeleteMenuLinkClick,

      handleDeleteMenuClick,

      // Sorting
      isSortingActive,
      isSaveSortedItemsLoading,
      saveSortedItems,
      resetSortedItems,
      handleOnDraggableMenuLinksChange,

      // Filters
      formatDate,
      avatarText,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
</style>
