import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import productModel from './productModel'
import productCategoryModel from '../product_categories/productCategoryModel'

const {
  fetchList,
  fetchAll,
  fetchOne,
  create,
  update,
  deleteMany,
} = useModelStoreModule(productModel)

const {
  fetchList: fetchCategories,
} = useModelStoreModule(productCategoryModel)

export default {
  name: 'products',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchList,
    fetchAll,
    fetchOne,
    create,
    update,
    deleteMany,

    fetchCategories,
  },
}
