<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isMenuAddEditSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-menu-add-edit-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            v-if="menuLocal.id"
            size="sm"
          >
            {{ $t('modules.menus.actions.edit_menu') }}
          </h5>
          <h5
            v-else
            class="mb-0"
          >
            {{ $t('modules.menus.actions.add_menu') }}
          </h5>
          <div>
            <feather-icon
              v-show="menuLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-menu', menuLocal.id); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Title -->
            <i-input
              id="itemName"
              ref="itemName"
              v-model="menuLocal.title"
              :errors="[]"
              type="text"
              title="inputs.name"
              :validation-rules="['required']"
              :translatable="false"
            />

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ $t(menuLocal.id ? 'Edit' : 'Add') }}
              </b-button>
              <b-button
                v-t="`Cancel`"
                variant="outline-secondary"
                @click="hide"
              />
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs } from 'vue'
import useMenuHandler from './useMenuHandler'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,

    // 3rd party packages

    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isMenuAddEditSidebarActive',
    event: 'update:is-menu-add-edit-sidebar-active',
  },
  props: {
    isMenuAddEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    menu: {
      type: Object,
      required: true,
    },
    clearMenuData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    const {
      menuLocal,
      resetTaskLocal,

      // UI
      assigneeOptions,
      onSubmit,
    } = useMenuHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetTaskLocal, props.clearMenuData)

    return {
      // Add New
      menuLocal,
      onSubmit,
      assigneeOptions,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // UI

      // Filter/Formatter
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
