import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import pageModel from './pageModel'

const {
  fetchList,
  fetchAll,
  fetchOne,
  create,
  update,
  deleteMany,
  sorting,
} = useModelStoreModule(pageModel)

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchList,
    fetchAll,
    fetchOne,
    create,
    update,
    deleteMany,
    sorting,
  },
}
