export default {
  name: 'pages',

  translatedAttributes: [
    'name',
    'description',
  ],

  /**
   * Prepare item data for backend request
   * @param item
   * @returns Object
   */
  toArray: item => ({
    id: item.id,
    title_en: item.title.en,
    title_ar: item.title.ar,
    description_en: item.description.en,
    description_ar: item.description.ar,
    is_active: item.is_active,
  }),

  /**
   * Parse backend payload to item object
   * @param data
   * @param initialItemData
   * @returns Object
   */
  fromArray: (data, initialItemData = {}) => ({
    ...initialItemData,
    id: data.id,
    title: {
      en: data.title_en,
      ar: data.title_ar,
    },
    description: {
      en: data.description_en,
      ar: data.description_ar,
    },
    is_active: !!data.is_active,
  }),

  resolveValidationErrors: errors => ({
    id: errors.id,
    name: {
      en: errors.name_en,
      ar: errors.name_ar,
    },
    description: {
      en: errors.description_en,
      ar: errors.description_ar,
    },
    is_active: errors.is_active,
  }),
}
