<template>
  <li
    class="todo-item pl-2"
    @click="handleTaskClick(link)"
  >
    <!--    <feather-icon-->
    <!--      icon="MoreVerticalIcon"-->
    <!--      class="draggable-task-handle d-inline"-->
    <!--    />-->
    <div class="todo-title-wrapper">
      <div class="todo-title-area">
        <material-icon
          icon="drag_indicator"
          color="#a0a0a0"
          class="draggable-task-handle d-inline"
        />

        <feather-icon
          v-if="nesting"
          :icon="visible ? 'ChevronDownIcon' : 'ChevronLeftIcon'"
          color="#a0a0a0"
          class="cursor-pointer"
          @click="updateVisible(!visible)"
        />

        <div class="title-wrapper">
          <span class="todo-title">{{ link.title[$i18n.locale] || link.title['en'] || link.title['ar'] }}</span>
        </div>
      </div>
      <div class="todo-item-action">
        <!--        v-b-toggle.add-new-sidebar-->
        <b-button
          v-if="nesting"
          v-ripple.400="'rgb(49, 49, 49, 0.15)'"
          variant="link"
          class="p-1 text-secondary btn-link"
          @click="$emit('click-menu-link-add', link.id)"
        >
          <feather-icon
            v-b-tooltip.hover.top="$t(`modules.menus.actions.add_sub_link`)"
            icon="PlusIcon"
            size="16"
          />
        </b-button>
        <b-button
          v-ripple.400="'rgb(49, 49, 49, 0.15)'"
          variant="link"
          class="p-1 text-secondary btn-link"
          @click="$emit('click-menu-link-edit', link)"
        >
          <feather-icon
            v-b-tooltip.hover.top="$t(`Edit`)"
            icon="EditIcon"
            size="16"
          />
        </b-button>
        <b-button
          v-ripple.400="'rgb(49, 49, 49, 0.15)'"
          variant="link"
          class="p-1 text-secondary btn-link"
          @click="$emit('remove-menu-link', link.id)"
        >
          <feather-icon
            v-b-tooltip.hover.top="$t(`Delete`)"
            icon="TrashIcon"
            size="16"
          />
        </b-button>
      </div>
    </div>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="null"
      role="tabpanel"
    >
      <!--      <b-card-body>-->

      <draggable
        v-if="nesting"
        v-model="link.links"
        handle=".draggable-task-handle"
        tag="ul"
        class="todo-task-list media-list dragArea"
        :group="{
          name: `menu-link-children-${link.id}`,
          put: handleDropPolicy
        }"
        @change="$emit('draggable-change', link.links)"
      >
        <!--        <div-->
        <!--          v-if="!link.links || !link.links.length"-->
        <!--          slot="header"-->
        <!--          class="btn-group list-group-item"-->
        <!--          role="group"-->
        <!--          aria-label="Basic example"-->
        <!--        >-->
        <!--          <p class="text-center text-secondary">-->
        <!--            {{ $t('modules.menus._messages.no_sub_links') }}-->
        <!--          </p>-->
        <!--        </div>-->
        <MenuNestableLink
          v-for="subLink in link.links"
          :key="subLink.id"
          :link="subLink"
          :nesting="false"
          @click-menu-link-edit="payload => $emit('click-menu-link-edit', payload)"
          @remove-menu-link="payload => $emit('remove-menu-link', payload)"
        />
      </draggable>
      <!--      </b-card-body>-->
    </b-collapse>
  </li>

</template>

<script>
import {
  BButton, BCollapse, VBTooltip,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'MenuNestableLink',
  components: {
    BCollapse,
    BButton,
    draggable,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  emits: ['click-menu-link-add', 'click-menu-link-edit', 'remove-menu-link', 'visible', 'draggable-change'],
  props: {
    link: {
      type: Object,
      required: true,
    },
    nesting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      visible: true,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  created() {
    this.collapseItemID = uuidv4()
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
  },
  setup(props, ctx) {
    const handleTaskClick = taskData => {
      // Do nothing
    }

    // Limit nesting to single level
    const handleDropPolicy = (to, from, item) => !item.lastChild.children?.[0]?.hasChildNodes()

    return {
      // Event Handlers
      handleTaskClick,
      handleDropPolicy,
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
    //position: absolute;
    left: 8px;
    //top: 50%;
    //transform: translateY(-50%);
    //visibility: hidden;
    cursor: move;

    //.todo-task-list .todo-item:hover & {
    //  visibility: visible;
    //}
}

.dragArea {
  min-height: 50px;
  outline: 1px dashed var(--secondary);
}

.feather-edit:hover {
  color: var(--info)
}
.feather-trash:hover {
  color: var(--danger)
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-menus.scss";
</style>
