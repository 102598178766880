var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-task-handler","sidebar-class":"sidebar-lg","visible":_vm.isMenuLinkAddEditSidebarActive,"bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-add-edit-menu-link-sidebar-active', val); },"hidden":_vm.clearForm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[(_vm.menuLinkLocal.id)?_c('h5',{attrs:{"size":"sm"}},[_vm._v(" "+_vm._s(_vm.$t('modules.menus.actions.edit_link'))+" ")]):_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('modules.menus.actions.add_link'))+" ")]),_c('div',[_c('feather-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuLinkLocal.id),expression:"menuLinkLocal.id"}],staticClass:"cursor-pointer",attrs:{"icon":"TrashIcon"},on:{"click":function($event){_vm.$emit('remove-menu-link', _vm.menuLinkLocal.id); hide();}}}),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('i-input',{ref:"itemName",attrs:{"id":"itemName","errors":[],"type":"text","title":"inputs.name","validation-rules":['required'],"translatable":true},model:{value:(_vm.menuLinkLocal.title),callback:function ($$v) {_vm.$set(_vm.menuLinkLocal, "title", $$v)},expression:"menuLinkLocal.title"}}),_c('i-input',{ref:"itemEntityType",attrs:{"id":"itemEntityType","errors":[],"type":"select","reduce":function (o) { return o.key; },"options":[
              {
                key: 'PRODUCT',
                label: _vm.$t('inputs.link_type_options.PRODUCT')
              },
              {
                key: 'CATEGORY',
                label: _vm.$t('inputs.link_type_options.CATEGORY')
              },
              {
                key: 'PAGE',
                label: _vm.$t('inputs.link_type_options.PAGE')
              },
              {
                key: 'CUSTOM',
                label: _vm.$t('inputs.link_type_options.CUSTOM')
              } ],"title":"inputs.link_type","validation-rules":['required'],"translatable":false},on:{"input":function (val) { return _vm.loadEntityOptions(val); }},model:{value:(_vm.menuLinkLocal.entity_type),callback:function ($$v) {_vm.$set(_vm.menuLinkLocal, "entity_type", $$v)},expression:"menuLinkLocal.entity_type"}}),_c('Transition',{attrs:{"name":"fade-bottom"}},[(_vm.menuLinkLocal.entity_type === 'CUSTOM')?_c('i-input',{ref:"itemUrl",staticClass:"text-left",class:{'ltr': !!_vm.menuLinkLocal.url},attrs:{"id":"itemUrl","errors":[],"type":"text","title":"inputs.url","validation-rules":['required'],"translatable":false},model:{value:(_vm.menuLinkLocal.url),callback:function ($$v) {_vm.$set(_vm.menuLinkLocal, "url", $$v)},expression:"menuLinkLocal.url"}}):_vm._e()],1),_c('Transition',{attrs:{"name":"fade-bottom"}},[(_vm.menuLinkLocal.entity_type && _vm.menuLinkLocal.entity_type !== 'CUSTOM')?_c('i-input',{ref:"itemEntityId",attrs:{"id":"itemEntityId","errors":[],"type":"select","options":_vm.entityOptions,"reduce":function (o) { return String(o.id); },"title":"inputs.entity_id","validation-rules":['required'],"translatable":false,"disabled":_vm.isEntityLoading,"input-bindings":{
                loading: _vm.isEntityLoading
              }},model:{value:(_vm.menuLinkLocal.entity_id),callback:function ($$v) {_vm.$set(_vm.menuLinkLocal, "entity_id", $$v)},expression:"menuLinkLocal.entity_id"}}):_vm._e()],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.menuLinkLocal.id ? 'Edit' : 'Add'))+" ")]),_c('b-button',{directives:[{name:"t",rawName:"v-t",value:("Cancel"),expression:"`Cancel`"}],attrs:{"variant":"outline-secondary"},on:{"click":hide}})],1)],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }